// Layout
$layout-row-height: 50px;
$layout-gutter-height: 50pt;
$side-nav-width: 200px;
$full-width: calc(100vw - (100vw - 100%));
$base-padding: 15px;
$table-row-padding: 0.75rem;

// Breakpoints
$breakpoint-mobile: 865px;

// Colors
$primary-color: #008ba2;
$secondary-color: #526173;

// Z indexes
$date-picker-popover-z-index: 10;
