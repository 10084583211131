// Make element completely unselectable (and un-inspectable for <button>s, unintended side effect, unfortunately)
%unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.unselectable {
  @extend %unselectable;
}

@mixin mobile() {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $breakpoint-mobile) {
    @content;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
}

.background-hover {
  &:hover {
    @extend .bg-light;
  }
}

.primary-color-hover:hover {
  color: $primary-color !important;
}

.primary-color-bg-hover:hover {
  background-color: rgba($color: $primary, $alpha: .5);
  color: white;
}

.bg-danger {
  &-7 {
    background-color: rgba($red, 0.7);
  }
}

.bg-success {
  &-7 {
    background-color: rgba($green, 0.7);
  }
}

.bg-warning {
  &-4 {
    background-color: rgba($yellow, 0.4);
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.font-size {
  &-small {
    font-size: 11pt;
  }
}

.bold {
  font-weight: 700;
}

.white-opaque-background {
  padding: 10px;
  background-color: rgba(white, 0.4);
}

.color-secondary {
  color: $secondary;
}