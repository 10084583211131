body {
  height: 100vh;
}

#root {
  height: 100%;

  > div:first-child {
    height: 100%;
    display: flex;
  }
}

// Square Delete Icon
.hover-toggle {
  &:not(:hover) .show-when-hovered {
    @extend .hidden;
  }

  &:hover {
    .hide-when-hovered {
      @extend .hidden;
    }
  }
}

.expandable {
  transition: height 0.2s ease-out;
  height: 0;
  overflow: hidden;
}

.unit-toggle {
  border: solid 1px $primary-color;
  padding: 5px;
  display: flex;

  > div {
    cursor: pointer;
    width: 40px;
    height: 100%;
    color: $primary-color;
    text-align: center;
    border: solid 1px white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
      background-color: $primary-color;
      color: white;
    }
  }
}



.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .micro {
    height: 10px;
    width: 10px;
  }

  .small {
    height: 25px;
    width: 25px;
  }

  .medium {
    height: 100px;
    width: 100px;
  }

  .large {
    height: 200px;
    width: 200px;
  }

  &.padding {
    padding-bottom: 10%;
  }
}

.loading-overlay {
  height: 100%;
  width: 100%;

  position: sticky;

  &.fixed {
    position: fixed;
  }

  &.absolute {
    position: absolute;
  }

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.full-screen {
    position: fixed;
  }

  background-color: rgba(white, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
