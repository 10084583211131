.form-group {
  margin-bottom: 5px;
}

.day-picker-input {
  margin-bottom: 1px;
  @extend .text-muted;
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.7;
  }

  &.expand {
    width: 400px;
  }
}

.date-picker-popover {
  @extend .bg-light;
  z-index: $date-picker-popover-z-index;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @extend .popover;
}

.input-icon {
  position: absolute;
  top: 1px;
  right: 0;
  height: calc(100% - 2px);
  padding: 0 8px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  margin-right: 2px;
}

.hidden {
  display: none !important;
}
