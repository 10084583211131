// Bootstrap overrides and extensions
.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.icon-right {
  svg {
    margin-bottom: 4px;
    margin-left: 4px;
  }
}

.icon-left {
  svg {
    margin-bottom: 4px;
    margin-right: 4px;
  }
}

.btn {
  &.ellipses-button {
    display: flex;
    &::after {
      display: none;
    }
  }

  &-extra-sm {
    padding: 0.15rem 0.4rem;
    font-size: 11pt;
  }

  .badge {
    &.margin-left {
      margin-left: 8px;
    }

    &.margin-right {
      margin-right: 8px;
    }
  }

  &:disabled {
    cursor: default;
  }

  &.min-width {
    &-sm {
      min-width: 75px;
    }
    &-md {
      min-width: 130px;
    }
    &-lg {
      min-width: 175px;
    }
    &-xl {
      min-width: 250px;
    }
  }
}

.table {
  margin-bottom: 0;

  th {
    padding: 0;
  }

  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr {
    min-height: $layout-row-height;
  }

  thead.borderless th {
    border-top: none;
    vertical-align: middle;
    border-bottom-width: 1px;
    height: 50px;
  }
}

.page-item {
  cursor: pointer;
  @extend %unselectable;

  &.disabled {
    cursor: unset;
  }

  &.active.disabled {
    .page-link {
      color: white;
      background-color: $blue;
      border-color: $blue;
      opacity: 0.7;
    }
  }
}

.custom-checkbox {
  label {
    cursor: pointer;
  }
}

.modal.allow-overflow {
  .modal-content {
    overflow: visible;
  }

  .modal-body {
    overflow-y: visible;
  }
}

.modal-fullscreen {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

.alerts-modal {
  z-index: 1051;
  background-color: rgba(255, 255, 255, 0.75);
}

.tag {
  @extend .btn;
  @extend .btn-secondary;
  @extend .btn-sm;

  padding: 0.25rem;

  .tag-icon {
    margin-right: 3px;
  }

  .close-icon {
    margin-left: 3px;
    margin-bottom: 3px;
  }
}

.modal-xxl {
  max-width: 1340px;
}

.invalid-feedback {
  margin-top: 0;
}

ul {
  list-style-type: initial;
  padding: 0 0 0 40px;
}

.toast {
  background-color: rgba(255, 255, 255, 0.95);
}

.page-link {
  padding: 0.35rem 0.5rem;
  font-size: 0.875rem;
}

label {
  margin-bottom: 0.1rem;
  font-weight: 500;
  font-size: 11pt;
}

.form-check.indicate-hover {
  cursor: pointer;

  &:hover {
    background-color: rgba($color: $primary, $alpha: 0.2);
  }
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    width: 100%;

    @extend .form-control;
  }
}

.custom-control.disabled {
  .custom-control-label::before {
    color: #fff;
    border-color: $gray-500;
    background: $gray-500 linear-gradient(180deg, $gray-500, $gray-500) repeat-x;
    cursor: default;
  }
  
  .custom-control-label::after {
    cursor: default;
  }
}

